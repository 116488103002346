"use client";

import ErrorPage from "~/components/ErrorPage";

export default function GlobalError() {
  return (
    <html>
      <body>
        <ErrorPage />
      </body>
    </html>
  );
}
