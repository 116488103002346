import { ReactNode } from "react";
import Image from "next/image";
import ventiq from "~/assets/ventiq_Logo_Design_V1.svg";

export default function ErrorPage(): ReactNode {
  return (
    <main className="flex h-screen w-screen flex-col items-center justify-center">
      <Image
        priority
        src={ventiq}
        alt="egocentric Systems logo"
        className="max-w-1/2 h-auto w-full"
      />
      <h1>The Shop you are looking for is not available</h1>
    </main>
  );
}
